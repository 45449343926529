import { List } from '@mui/material'

import { useUtbildningsBokning } from '../../../../utils/Context/UtbildningsbokningContext'
import { IUtbildningsbokning } from '../../../types'

import AdvisorProcessStatus from './AdvisorProcessStatus/AdvisorProcessStatus'
import ApplicationProcessStatus from './ApplicationProcessStatus/ApplicationProcessStatus'
import SupplierProcessStatus from './SupplierProcessStatus/SupplierProcessStatus'

const ProcessSteps = () => {
  const { statuses } = useUtbildningsBokning() as IUtbildningsbokning

  const hasBeenCancelledInSomeStep = [
    statuses?.isLateCancelledByClient,
    statuses?.isCancelledByClient,
    statuses?.isCancelledBySupplier,
  ].some((s) => s)

  const hasBeenRejectedInSomeStep = [
    statuses?.isRejectedBySupplier,
    statuses?.isRejectedByAdvisor,
  ].some((s) => s)

  return (
    <List sx={{ mb: 8 }}>
      <ApplicationProcessStatus
        isCancelled={hasBeenCancelledInSomeStep}
        isRejected={hasBeenRejectedInSomeStep}
      />
      <AdvisorProcessStatus
        isCancelled={hasBeenCancelledInSomeStep}
        isRejected={hasBeenRejectedInSomeStep}
      />
      <SupplierProcessStatus
        isCancelled={hasBeenCancelledInSomeStep}
        isRejected={hasBeenRejectedInSomeStep}
      />
    </List>
  )
}

export default ProcessSteps
