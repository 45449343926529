import React from 'react'
import { Box } from '@mui/material'

interface IWysiwygEditorProps {
  htmlFormattedString: string
}

const WysiwygEditor: React.FC<IWysiwygEditorProps> = ({
  htmlFormattedString,
}) => {
  //Epi returns whitespaces in brödtextblock as <p> </p>
  const stringWithWhiteSpace = htmlFormattedString.replaceAll(
    '<p> </p>',
    '&nbsp'
  )
  //Epi returns links not opening in new tab
  const linksTargetBlank = stringWithWhiteSpace.replaceAll(
    '<a',
    '<a target="_blank" '
  )
  if (!htmlFormattedString) return null
  return (
    <Box
      sx={{
        wordWrap: 'break-word',
        '& a': {
          color: 'rgb(98, 34, 195)',
          textDecoration: 'none',
          display: 'inline-flex !important',
          alignItems: 'center',
          fontWeight: 500,
          verticalAlign: 'top',
          '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
          },
        },
      }}
      dangerouslySetInnerHTML={{ __html: linksTargetBlank }}
    />
  )
}

export default WysiwygEditor
