import { Link as MuiLink, Typography } from '@mui/material'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useEpiPagePath } from '@trr/app-shell-data'

import { PageWrapper } from '../../Components'
import BookingStatusChip from '../../Components/BookingStatusChip/BookingStatusChip'
import Divider from '../../Components/Divider'
import WysiwygEditor from '../../Components/WysiwygEditor'
import {
  useEpiEditorialBlock,
  useEpiLink,
  useEpiString,
} from '../../utils/Context/EpiContext'
import {
  useUtbildningsBokning,
  UtbildningsbokningProvider,
} from '../../utils/Context/UtbildningsbokningContext'
import { useGetUtbildningQuery } from '../../utils/Network/api'
import { IUtbildningsbokning, mapUtbildningsBokningToViewModel } from '../types'

import { AddToCalendar, DetailedInformation, ProcessSteps } from './components'
import CancellationStatusBox from './components/CancellationStatusBox/CancellationStatusBox'

const UtbildnignsbokningarDetails: React.FunctionComponent = () => {
  const url = useEpiPagePath()

  const routeMatch = useRouteMatch<{ id: string }>(`${url}:id`)
  const id = routeMatch?.params?.id ?? ''

  const { data, isError, isLoading, isSuccess } = useGetUtbildningQuery(id, {
    skip: !id || !url,
  })

  const emptyResponse = isSuccess && !data
  const bookingDetails = data && mapUtbildningsBokningToViewModel(data)
  const epiLink = useEpiLink()
  const epi = useEpiString()

  const allmanInfoTextBlock = useEpiEditorialBlock('allmanInfo')
  const { statuses } = useUtbildningsBokning() as IUtbildningsbokning

  const cancellationStatus = [
    statuses?.isLateCancelledByClient,
    statuses?.isCancelledByClient,
    statuses?.isCancelledBySupplier,
    statuses?.isRejectedByAdvisor,
    statuses?.isRejectedBySupplier,
  ].some((s) => s)

  const showCalendarButton =
    bookingDetails?.startDate &&
    bookingDetails?.startDate > new Date() &&
    !cancellationStatus

  return (
    <UtbildningsbokningProvider value={bookingDetails ?? {}}>
      <PageWrapper
        isLoading={isLoading}
        errorText={'Kunde inte hämta bokningen'}
        errorLoadingData={isError || !id || emptyResponse}
      >
        <Typography sx={{ wordBreak: 'break-word' }} gutterBottom variant="h1">
          {bookingDetails?.instituteName}
        </Typography>
        <BookingStatusChip />
        <DetailedInformation />
        {showCalendarButton && <AddToCalendar />}
        <Divider />
        <CancellationStatusBox />
        <ProcessSteps />
        <Typography gutterBottom variant="body1">
          Uppdateringar kring bokningen skickas även till{' '}
          {bookingDetails?.contactEmail}
        </Typography>
        <Divider />
        {allmanInfoTextBlock.mainBody && (
          <WysiwygEditor htmlFormattedString={allmanInfoTextBlock.mainBody} />
        )}
        <MuiLink
          component="a"
          target="_blank"
          href={`${epiLink('fcp-cancellation-link')}`}
        >
          {epi('goToCancellation')}
        </MuiLink>
      </PageWrapper>
    </UtbildningsbokningProvider>
  )
}

export default UtbildnignsbokningarDetails
