import { createTheme, ThemeProvider } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import React from 'react'
import { Provider } from 'react-redux'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'

import { MainContainer } from '../Components'
import UtbildnignsbokningarDetails from '../Utbildningsbokningar/UtbildningsbokningarDetails/UtbildningsbokningarDetails'
import UtbildningsbokningarList from '../Utbildningsbokningar/UtbildningsbokningarList/UtbildningsbokningarList'
import { AppShellRouter, KeyRoute } from '../utils/Router'
import { store } from '../utils/store/configureStore'

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  const theme = createTheme(themeOptions, defaultLocale)
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AppShellDataProvider value={appShellData}>
          <MainContainer>
            <AppShellRouter currentKey={appShellData.currentKey}>
              <KeyRoute urlKey="list">
                <UtbildningsbokningarList />
              </KeyRoute>
              <KeyRoute urlKey="details">
                <UtbildnignsbokningarDetails />
              </KeyRoute>
            </AppShellRouter>
          </MainContainer>
        </AppShellDataProvider>
      </Provider>
    </ThemeProvider>
  )
}

export default App
