import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import Check from '@mui/icons-material/Check'

import { BookingProcessStepStatus } from '../../../types'

interface IProcessStatusItemProps {
  label: string
  step: number
  status: BookingProcessStepStatus
  faded?: boolean
}

const ProcessStatusItem = ({
  step,
  label,
  status,
  faded,
}: IProcessStatusItemProps) => {
  const ProcessStepAvatar = () => {
    switch (status) {
      case BookingProcessStepStatus.Declined:
        return (
          <Avatar
            sx={{ height: '32px', width: '32px', backgroundColor: '#fdedf0' }}
          >
            <Avatar
              data-testid="exclamation-mark"
              sx={{
                height: '16px',
                width: '16px',
                backgroundColor: '#bf072f',
                fontSize: '12px',
              }}
            >
              !
            </Avatar>
          </Avatar>
        )
      case BookingProcessStepStatus.Confirmed:
        return (
          <Avatar
            sx={{ height: '32px', width: '32px', backgroundColor: '#e3f8f6' }}
          >
            <Check
              sx={{
                backgroundColor: 'rgb(8, 145, 136)',
                borderRadius: '50%',
                fontSize: '16px',
              }}
            />
          </Avatar>
        )
      default:
        return (
          <Avatar
            sx={{
              height: '32px',
              width: '32px',
              backgroundColor: '#F2ECF8',
              color: '#6222c3',
              fontSize: '14px',
            }}
          >
            {step.toString()}
          </Avatar>
        )
    }
  }

  return (
    <ListItem sx={{ padding: 1, opacity: faded ? '.5' : '1', border: 'none' }}>
      <ListItemAvatar>
        <ProcessStepAvatar />
      </ListItemAvatar>
      <ListItemText primary={label} />
    </ListItem>
  )
}

export default ProcessStatusItem
