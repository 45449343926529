import React from 'react'
import { Chip } from '@mui/material'

import { useEpiString } from '../../utils/Context/EpiContext'
import { useUtbildningsBokning } from '../../utils/Context/UtbildningsbokningContext'
import { IUtbildningsbokning } from '../../Utbildningsbokningar/types'

export enum ChipStatus {
  WaitingForAdvisor = 'statusChip.waitingForAdvisor',
  WaitingForSupplier = 'statusChip.waitingForSupplier',
  Booked = 'statusChip.booked',
  Completed = 'statusChip.completed',
  CancelledBySupplier = 'statusChip.cancelledBySupplier',
  CancelledByClient = 'statusChip.cancelledByClient',
  RejectedBySupplier = 'statusChip.rejectedBySupplier',
  RejectedByAdvisor = 'statusChip.rejectedByAdvisor',
}

const BookingStatusChip: React.FC = () => {
  const epi = useEpiString()

  const { statuses } = useUtbildningsBokning() as IUtbildningsbokning

  const statusChips: {
    status: boolean | null
    key: ChipStatus
    type: 'success' | 'error' | 'warning'
  }[] = [
    {
      status: statuses?.isRejectedByAdvisor,
      key: ChipStatus.RejectedByAdvisor,
      type: 'error',
    },
    {
      status: statuses?.isRejectedBySupplier,
      key: ChipStatus.RejectedBySupplier,
      type: 'error',
    },

    {
      status: statuses?.isCancelledBySupplier,
      key: ChipStatus.CancelledBySupplier,
      type: 'error',
    },
    {
      status: statuses?.isCancelledByClient,
      key: ChipStatus.CancelledByClient,
      type: 'error',
    },
    {
      status: statuses?.isLateCancelledByClient,
      key: ChipStatus.CancelledByClient,
      type: 'error',
    },

    {
      status: statuses?.isWaitingForAdvisorApproval,
      key: ChipStatus.WaitingForAdvisor,
      type: 'warning',
    },
    {
      status: statuses?.isWaitingForSupplierConfirmation,
      key: ChipStatus.WaitingForSupplier,
      type: 'warning',
    },
    {
      status: statuses?.isAcceptedBySupplier,
      key: ChipStatus.Booked,
      type: 'success',
    },
  ]

  const status = statusChips.find((c) => c.status)

  if (!status) {
    return null
  }

  return <Chip variant={`status-${status.type}`} label={epi(status.key)} />
}

export default BookingStatusChip
