import React, { PropsWithChildren } from 'react'

export const AppShellRouter: React.FC<
  PropsWithChildren<{
    currentKey: string
  }>
> = ({ currentKey, children }) => {
  let component = null

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (child.props.urlKey === currentKey) {
        component = child
      }
    } else {
      component = null
    }
  })

  return component
}
