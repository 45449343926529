import { AdvisorApprovalStatus } from './AdvisorApprovalStatus.types'
import { IUtbildningBokningStatuses } from './IUtbildningsbokningGetModel.types'
import { IUtbildningsbokningListItem } from './IUtbildningsbokningListItem.types'
import { SupplierResponse } from './SupplierResponse.types'

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

export interface IUtbildningsbokningListItemGetModel {
  id?: string
  educationName?: string
  bookingDate?: string
  courseStartDate?: string
  courseLengthInDays?: number
  instituteName?: string
  advisorApprovalStatus: string
  supplierResponseAccepted?: boolean
  supplierResponse: string
  hasAttented?: boolean
  isDistance?: boolean
  placeDescription?: string
  statuses: IUtbildningBokningStatuses
  attendanceReport?: {
    attendance?: true
    recievedDate?: string
    submittedBy?: string
    comment?: string
  }
}

export const mapUtbildningsBokningListItemsToViewModel = (
  bookings: DeepPartial<IUtbildningsbokningListItemGetModel>[]
): IUtbildningsbokningListItem[] =>
  bookings.map((booking) => ({
    id: booking.id ?? '',
    instituteName: booking.instituteName,
    educationName: booking.educationName,
    advisorStatus: booking.advisorApprovalStatus as AdvisorApprovalStatus,
    supplierStatus: booking.supplierResponse as SupplierResponse,
    statuses: {
      isRejectedByAdvisor: booking.statuses?.isRejectedByAdvisor ?? null,
      isApprovedByAdvisor: booking.statuses?.isApprovedByAdvisor ?? null,
      isWaitingForAdvisorApproval:
        booking.statuses?.isWaitingForAdvisorApproval ?? null,
      isCancelledBySupplier: booking.statuses?.isCancelledBySupplier ?? null,
      isRejectedBySupplier: booking.statuses?.isRejectedBySupplier ?? null,
      isWaitingForSupplierConfirmation:
        booking.statuses?.isWaitingForSupplierConfirmation ?? null,
      isAcceptedBySupplier: booking.statuses?.isAcceptedBySupplier ?? null,
      isCancelledByClient: booking.statuses?.isCancelledByClient ?? null,
      isLateCancelledByClient:
        booking.statuses?.isLateCancelledByClient ?? null,
    },
    bookingDate: booking.bookingDate
      ? new Date(booking.bookingDate)
      : undefined,
    startDate: booking.courseStartDate
      ? new Date(booking.courseStartDate)
      : undefined,
  }))
