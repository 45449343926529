import { useEpiContent } from '@trr/app-shell-data'
import { IEditorialBlockContent } from 'src/App/App.types'

const replaceVariables = (
  text: string,
  variables: { [key: string]: string } = {}
) => {
  let result = text
  Object.keys(variables).forEach((key) => {
    const regex = new RegExp(`{{${key}}}`, 'g')
    result = result.replace(regex, variables[key])
  })
  return result
}

export const useEpiLink = () => {
  const epiContent = useEpiContent()
  const { lankar = {} } = epiContent || {}
  const links = lankar as { [key: string]: string }

  return (key: string) => links?.[key] ?? ''
}
export const useEpiString = () => {
  const epiContent = useEpiContent()
  const { innehall = {} } = epiContent || {}
  const strings = innehall as { [key: string]: string }

  return (key: string, variables?: { [key: string]: string }) =>
    strings?.[key] ? replaceVariables(strings[key], variables) : key
}
export const useEpiEditorialBlock = (
  key: 'allmanInfo'
): IEditorialBlockContent => {
  const content = useEpiContent()

  return (
    (content?.[key] as IEditorialBlockContent) ?? {
      mainBody: `Content for key:${key} not found`,
      name: '',
    }
  )
}
