import { List } from '@mui/material'

import { useGetUtbildningarQuery } from '../../utils/Network/api'
import { PageWrapper } from '../../Components'
import { mapUtbildningsBokningListItemsToViewModel } from '../types'

import { UtbildningsbokningListItem } from './components'

const UtbildningsbokningarList: React.FunctionComponent = () => {
  const { data, isLoading, isError } = useGetUtbildningarQuery('')
  const bookings = data ? mapUtbildningsBokningListItemsToViewModel(data) : []

  bookings.sort(
    (a, b) => (a.startDate?.valueOf() ?? 0) - (b.startDate?.valueOf() ?? 0)
  )

  return (
    <PageWrapper
      isLoading={isLoading}
      errorText={'Kunde inte hämta bokningar'}
      errorLoadingData={isError}
    >
      {bookings.length < 1 && <div>Kunde inte hitta några bokningar</div>}
      <List>
        {bookings.map((booking) => (
          <UtbildningsbokningListItem key={booking.id} booking={booking} />
        ))}
      </List>
    </PageWrapper>
  )
}

export default UtbildningsbokningarList
