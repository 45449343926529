import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'

import { useEpiLink } from '../../../../utils/Context/EpiContext'
import { IUtbildningsbokningListItem } from '../../../types'
import BookingStatusChip from '../../../../Components/BookingStatusChip/BookingStatusChip'
import { UtbildningsbokningProvider } from '../../../../utils/Context/UtbildningsbokningContext'

interface IUtbildningsbokningListItemProps {
  booking: IUtbildningsbokningListItem
}

const UtbildningsbokningListItem = ({
  booking,
}: IUtbildningsbokningListItemProps) => {
  const epiLink = useEpiLink()

  return (
    <UtbildningsbokningProvider value={booking}>
      <ListItem divider={false} sx={{ p: 0 }}>
        <Card sx={{ width: '100%', maxWidth: '700px', mb: 2 }}>
          <CardActionArea
            data-testid="utbildningsbokning-list-item"
            component="a"
            href={`${epiLink('details-link')}${booking.id ?? ''}`}
          >
            <CardContent>
              <Typography variant="h5">{booking.instituteName}</Typography>
              <Stack
                sx={{ mt: 1 }}
                justifyContent={'space-between'}
                direction="row"
              >
                <Box>
                  <ListItemText
                    primary={booking.educationName}
                    secondary={booking.id && `Bokningsnummer ${booking.id}`}
                  />
                </Box>
                <BookingStatusChip />
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      </ListItem>
    </UtbildningsbokningProvider>
  )
}

export default UtbildningsbokningListItem
