import { toCalenderDateString } from './toCalendarDateString'

export const googleCalendarEventLink = (
  startDate: Date,
  endDate: Date,
  title: string,
  description: string
): string =>
  `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${toCalenderDateString(
    startDate
  )}/${toCalenderDateString(endDate)}&details=${description}&sf=true&output=xml`
