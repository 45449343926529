/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React from 'react'

import { useEpiString } from '../../../../../utils/Context/EpiContext'
import { BookingProcessStepStatus } from '../../../../types'
import ProcessStatusItem from '../../ProcessStatusItem/ProcessStatusItem'

interface IApplicationProcessStatus {
  isCancelled?: boolean
  isRejected?: boolean
}

const ApplicationProcessStatus: React.FC<IApplicationProcessStatus> = ({
  isRejected,
  isCancelled,
}) => {
  const epi = useEpiString()

  return (
    <ProcessStatusItem
      label={epi('applicationProcessStatus.label')}
      step={1}
      status={
        isCancelled
          ? BookingProcessStepStatus.None
          : BookingProcessStepStatus.Confirmed
      }
      faded={isCancelled || isRejected}
    />
  )
}

export default ApplicationProcessStatus
