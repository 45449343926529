export enum BookingsStatus {
  Booked = 'booked',
  Cancelled = 'cancelled',
  WaitingForBookingConfirmation = 'waitingForBookingConfirmation',
  WaitingForAdvisorResponse = 'waitingForAdvisorResponse',
  Completed = 'completed',
  Unknown = 'unknown',
  ClientLateBookingCancellation = 'clientLateBookingCancellation',
  ClientBookingCancellation = 'clientBookingCancellation',
  AdvisorRejected = 'advisorRejected',
  CourseCancelled = 'courseCancelled',
  RejectedFullyBooked = 'rejectedFullyBooked',
  RejectedOther = 'rejectedOther',
}

export enum BookingProcessStep {
  BookingConfirmsBySupplier = 3,
  ApplicationConfirmsByAdvisor = 2,
  ApplicationReceived = 1,
}

export enum BookingProcessStepStatus {
  None = 0,
  Pending = 1,
  Confirmed = 2,
  Declined = 3,
}
