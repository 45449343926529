import React from 'react'

import {
  IUtbildningsbokning,
  IUtbildningsbokningListItem,
} from '../../Utbildningsbokningar/types'

const UtbildningsbokningContext = React.createContext<
  Partial<IUtbildningsbokning | IUtbildningsbokningListItem>
>({})

export const UtbildningsbokningProvider = UtbildningsbokningContext.Provider

export const useUtbildningsBokning = () =>
  React.useContext(UtbildningsbokningContext)
