import React, { PropsWithChildren } from 'react'

interface ISvgIconProps {
  size?: number
  title?: string
  viewBox?: string
}

export const SvgIcon: React.FunctionComponent<
  PropsWithChildren<ISvgIconProps>
> = ({ size = 28, children, title, viewBox = '0 0 512 512' }) => (
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={size}
    height={size}
  >
    <title>{title}</title>
    {children}
  </svg>
)

export const AppleLogo: React.FunctionComponent<ISvgIconProps> = ({
  title = 'Apple',

  ...props
}) => (
  <SvgIcon viewBox="48 48" title={title} {...props}>
    <path d="M25.565,9.785c-0.123,0.077-3.051,1.702-3.051,5.305c0.138,4.109,3.695,5.55,3.756,5.55 c-0.061,0.077-0.537,1.963-1.947,3.94C23.204,26.283,21.962,28,20.076,28c-1.794,0-2.438-1.135-4.508-1.135 c-2.223,0-2.852,1.135-4.554,1.135c-1.886,0-3.22-1.809-4.4-3.496c-1.533-2.208-2.836-5.673-2.882-9 c-0.031-1.763,0.307-3.496,1.165-4.968c1.211-2.055,3.373-3.45,5.734-3.496c1.809-0.061,3.419,1.242,4.523,1.242 c1.058,0,3.036-1.242,5.274-1.242C21.394,7.041,23.97,7.332,25.565,9.785z M15.001,6.688c-0.322-1.61,0.567-3.22,1.395-4.247 c1.058-1.242,2.729-2.085,4.17-2.085c0.092,1.61-0.491,3.189-1.533,4.339C18.098,5.937,16.488,6.872,15.001,6.688z" />
  </SvgIcon>
)

export const GoogleCalendarLogo: React.FunctionComponent<ISvgIconProps> = ({
  title = 'Google Calendar',

  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" title={title} {...props}>
    <rect width="22" height="22" x="13" y="13" fill="#fff" />
    <polygon
      fill="#1e88e5"
      points="25.68,20.92 26.688,22.36 28.272,21.208 28.272,29.56 30,29.56 30,18.616 28.56,18.616"
    />
    <path
      fill="#1e88e5"
      d="M22.943,23.745c0.625-0.574,1.013-1.37,1.013-2.249c0-1.747-1.533-3.168-3.417-3.168 c-1.602,0-2.972,1.009-3.33,2.453l1.657,0.421c0.165-0.664,0.868-1.146,1.673-1.146c0.942,0,1.709,0.646,1.709,1.44 c0,0.794-0.767,1.44-1.709,1.44h-0.997v1.728h0.997c1.081,0,1.993,0.751,1.993,1.64c0,0.904-0.866,1.64-1.931,1.64 c-0.962,0-1.784-0.61-1.914-1.418L17,26.802c0.262,1.636,1.81,2.87,3.6,2.87c2.007,0,3.64-1.511,3.64-3.368 C24.24,25.281,23.736,24.363,22.943,23.745z"
    />
    <polygon fill="#fbc02d" points="34,42 14,42 13,38 14,34 34,34 35,38" />
    <polygon fill="#4caf50" points="38,35 42,34 42,14 38,13 34,14 34,34" />
    <path
      fill="#1e88e5"
      d="M34,14l1-4l-1-4H9C7.343,6,6,7.343,6,9v25l4,1l4-1V14H34z"
    />
    <polygon fill="#e53935" points="34,34 34,42 42,34" />
    <path fill="#1565c0" d="M39,6h-5v8h8V9C42,7.343,40.657,6,39,6z" />
    <path fill="#1565c0" d="M9,42h5v-8H6v5C6,40.657,7.343,42,9,42z" />
  </SvgIcon>
)

export const Microsoft365Logo: React.FunctionComponent<ISvgIconProps> = ({
  title = 'Microsoft 365',

  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" title={title} {...props}>
    <path
      fill="#e65100"
      d="M36.883,7.341C38.726,7.85,40,9.508,40,11.397v25.162c0,1.906-1.301,3.57-3.168,4.065L25.29,43.863 L29,36V11l-3.148-6.885L36.883,7.341z"
    />
    <path
      fill="#b71c1c"
      d="M29,35v3.927c0,3.803-3.824,6.249-7.019,4.491l-6.936-4.445c-0.802-0.466-1.236-1.462-0.964-2.457 C14.334,35.59,15.202,35,16.115,35L29,35z"
    />
    <path
      fill="#b71c1c"
      d="M15.456,32.228l-3.4,1.502C10.694,34.532,9,33.518,9,31.901V14.904c0-1.536,0.811-2.95,2.116-3.691 l11.83-6.687C25.669,2.983,29,5.014,29,8.218v3.09l-10.037,3.486C17.78,15.263,17,16.436,17,17.743v11.742 C17,30.618,16.41,31.665,15.456,32.228z"
    />
  </SvgIcon>
)

export const OutlookLogo: React.FunctionComponent<ISvgIconProps> = ({
  title = 'Apple',

  ...props
}) => (
  <SvgIcon viewBox="0 0 48 48" title={title} {...props}>
    <path
      fill="#1a237e"
      d="M43.607,23.752l-7.162-4.172v11.594H44v-6.738C44,24.155,43.85,23.894,43.607,23.752z"
    />
    <path
      fill="#0c4999"
      d="M33.919,8.84h9.046V7.732C42.965,6.775,42.19,6,41.234,6H17.667c-0.956,0-1.732,0.775-1.732,1.732 V8.84h9.005H33.919z"
    />
    <path
      fill="#0f73d9"
      d="M33.919,33.522h7.314c0.956,0,1.732-0.775,1.732-1.732v-6.827h-9.046V33.522z"
    />
    <path
      fill="#0f439d"
      d="M15.936,24.964v6.827c0,0.956,0.775,1.732,1.732,1.732h7.273v-8.558H15.936z"
    />
    <path
      fill="#2ecdfd"
      d="M33.919 8.84H42.964999999999996V16.866999999999997H33.919z"
    />
    <path
      fill="#1c5fb0"
      d="M15.936 8.84H24.941000000000003V16.866999999999997H15.936z"
    />
    <path fill="#1467c7" d="M24.94 24.964H33.919V33.522H24.94z" />
    <path fill="#1690d5" d="M24.94 8.84H33.919V16.866999999999997H24.94z" />
    <path fill="#1bb4ff" d="M33.919 16.867H42.964999999999996V24.963H33.919z" />
    <path fill="#074daf" d="M15.936 16.867H24.941000000000003V24.963H15.936z" />
    <path fill="#2076d4" d="M24.94 16.867H33.919V24.963H24.94z" />
    <path
      fill="#2ed0ff"
      d="M15.441,42c0.463,0,26.87,0,26.87,0C43.244,42,44,41.244,44,40.311V24.438 c0,0-0.03,0.658-1.751,1.617c-1.3,0.724-27.505,15.511-27.505,15.511S14.978,42,15.441,42z"
    />
    <path
      fill="#139fe2"
      d="M42.279,41.997c-0.161,0-26.59,0.003-26.59,0.003C14.756,42,14,41.244,14,40.311V25.067 l29.363,16.562C43.118,41.825,42.807,41.997,42.279,41.997z"
    />
    <path
      fill="#00488d"
      d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"
    />
    <path
      fill="#fff"
      d="M13.914,18.734c-3.131,0-5.017,2.392-5.017,5.343c0,2.951,1.879,5.342,5.017,5.342 c3.139,0,5.017-2.392,5.017-5.342C18.931,21.126,17.045,18.734,13.914,18.734z M13.914,27.616c-1.776,0-2.838-1.584-2.838-3.539 s1.067-3.539,2.838-3.539c1.771,0,2.839,1.585,2.839,3.539S15.689,27.616,13.914,27.616z"
    />
  </SvgIcon>
)
