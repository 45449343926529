/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React from 'react'

import { useEpiString } from '../../../../../utils/Context/EpiContext'
import { useUtbildningsBokning } from '../../../../../utils/Context/UtbildningsbokningContext'
import {
  BookingProcessStepStatus,
  IUtbildningsbokning,
} from '../../../../types'
import ProcessStatusItem from '../../ProcessStatusItem/ProcessStatusItem'

interface ISupplierProcessStatus {
  isCancelled?: boolean
  isRejected?: boolean
}

const SupplierProcessStatus: React.FC<ISupplierProcessStatus> = ({
  isCancelled,
  isRejected,
}) => {
  const epi = useEpiString()

  const { statuses } = useUtbildningsBokning() as IUtbildningsbokning

  const steps: {
    isSet: boolean | null
    status: BookingProcessStepStatus
    label: string
  }[] = [
    {
      isSet: !!isCancelled,
      status: BookingProcessStepStatus.None,
      label: epi('supplierProcessStatus.pending'),
    },
    {
      isSet: statuses?.isRejectedBySupplier,
      status: BookingProcessStepStatus.Declined,
      label: epi('supplierProcessStatus.declined'),
    },
    {
      isSet: statuses?.isAcceptedBySupplier,
      status: BookingProcessStepStatus.Confirmed,
      label: epi('supplierProcessStatus.confirmed'),
    },
    {
      isSet: statuses?.isWaitingForSupplierConfirmation,
      status: BookingProcessStepStatus.Pending,
      label: epi('supplierProcessStatus.pending'),
    },
  ]

  const supplierProcessStepStatus = steps.find((s) => s.isSet) ?? {
    status: BookingProcessStepStatus.Pending,
    label: epi('supplierProcessStatus.pending'),
  }

  const isOtherStepRejected =
    isRejected &&
    supplierProcessStepStatus.status !== BookingProcessStepStatus.Declined
  return (
    <ProcessStatusItem
      label={supplierProcessStepStatus.label}
      step={3}
      status={supplierProcessStepStatus.status}
      faded={isCancelled || isOtherStepRejected}
    />
  )
}

export default SupplierProcessStatus
