import Warning from '@mui/icons-material/Warning'
import { Stack, Typography } from '@mui/material'
import React from 'react'

import { useEpiString } from '../../../../utils/Context/EpiContext'
import { useUtbildningsBokning } from '../../../../utils/Context/UtbildningsbokningContext'

export enum CancellationMessage {
  CourseCancelled = 'cancellationMessage.courseCancelled',
  ClientLateCancellation = 'cancellationMessage.clientLateCancellation',
  ClientCancellation = 'cancellationMessage.clientCancellation',
}

const CancellationStatusBox: React.FC = () => {
  const epi = useEpiString()

  const { statuses } = useUtbildningsBokning()

  const cancellationStatus = [
    {
      status: statuses?.isLateCancelledByClient,
      message: CancellationMessage.ClientLateCancellation,
    },
    {
      status: statuses?.isCancelledByClient,
      message: CancellationMessage.ClientCancellation,
    },
    {
      status: statuses?.isCancelledBySupplier,
      message: CancellationMessage.CourseCancelled,
    },
  ].find((s) => s.status)

  if (!cancellationStatus) {
    return null
  }

  return (
    <Stack gap={2} sx={{ p: 2 }} direction="row">
      <Warning color={'error'} />
      <Typography gutterBottom={false} variant="subtitle1">
        {epi(cancellationStatus.message)}
      </Typography>
    </Stack>
  )
}

export default CancellationStatusBox
