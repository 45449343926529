import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import {
  IUtbildningsbokningGetModel,
  IUtbildningsbokningListItemGetModel,
} from '../../Utbildningsbokningar/types'

import { prepareHeaders } from './prepareHeaders'
import { getAppConfig } from '@trr/app-shell-data'

const {
  COMMON: { KLIENT_API_URL },
} = getAppConfig()

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

export const utbildningsbokningarApi = createApi({
  reducerPath: 'utbildningsbokningarApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${KLIENT_API_URL}/kompetens/Booking/`,
    prepareHeaders,
  }),
  tagTypes: ['bokningar'],
  endpoints: (build) => ({
    getUtbildningar: build.query<
      DeepPartial<IUtbildningsbokningListItemGetModel>[],
      string
    >({
      query: () => ({ url: `/user` }),
    }),
    getUtbildning: build.query<
      DeepPartial<IUtbildningsbokningGetModel>,
      string
    >({
      query: (bookingId) => ({ url: `/user/${bookingId}` }),
    }),
  }),
})

export const { useGetUtbildningQuery, useGetUtbildningarQuery } =
  utbildningsbokningarApi
