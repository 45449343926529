import { toCalenderDateStringWithHyphen } from './toCalendarDateString'

export const microsoftOutlookEventLink = (
  startDate: Date,
  endDate: Date,
  title: string,
  description: string
): string => {
  //ics sets enddate to -1d, so we need to add 1d to the enddate
  const adjustedEndDate = new Date(new Date(endDate).getTime() + 60 * 60 * 24 * 1000);
  return  `https://outlook.office.com/calendar/0/deeplink/compose?subject=${title}&body=${description}&startdt=${toCalenderDateStringWithHyphen(
    startDate
  )}&enddt=${toCalenderDateStringWithHyphen(
    adjustedEndDate
  )}&allday=true&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent`}