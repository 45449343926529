import { createEvent, EventAttributes } from 'ics'
import { useRef, useState } from 'react'
import { saveAs } from 'file-saver'
import CalendarMonthOutlined from '@mui/icons-material/CalendarMonthOutlined'
import CalendarMonthSharp from '@mui/icons-material/CalendarMonthSharp'
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'

import { useEpiString } from '../../../../utils/Context/EpiContext'
import { useUtbildningsBokning } from '../../../../utils/Context/UtbildningsbokningContext'
import { useOnClickOutside } from '../../../../utils/Hooks/useClickOutside'
import {
  AppleLogo,
  GoogleCalendarLogo,
  OutlookLogo,
} from '../../../../Components/Icons/Icons'
import {
  googleCalendarEventLink,
  microsoftOutlookEventLink,
  toDateArray,
} from '../../../../utils/Helpers'

const AddToCalendar = () => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const epi = useEpiString()
  const [open, setOpen] = useState(false)
  const { startDate, educationName, id } = useUtbildningsBokning()

  useOnClickOutside(wrapperRef, () => {
    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
    return !isTouchDevice && setOpen(false)
  })

  if (!startDate) return null

  const title: string = epi('addToCalendar.title', {
    title: educationName ?? '',
  })

  const description: string = epi('addToCalendar.description', {
    bookingId: id ?? '',
  })

  const event: EventAttributes = {
    start: startDate && toDateArray(startDate),
    end: startDate && toDateArray(startDate),
    title,
    description,
  }

  const handleSave = () => {
    createEvent(event, (_error, value) => {
      const blob = new Blob([value], { type: 'text/calendar;charset=utf-8' })
      saveAs(blob, `${title ?? ''}.ics`)
    })
  }

  const calendarOption = [
    { onClick: handleSave, icon: <AppleLogo />, label: 'Apple Calendar' },
    {
      link: microsoftOutlookEventLink(startDate, startDate, title, description),
      icon: <OutlookLogo />,
      label: 'Outlook',
    },
    {
      link: googleCalendarEventLink(startDate, startDate, title, description),
      icon: <GoogleCalendarLogo />,
      label: 'Google',
    },
    {
      onClick: handleSave,
      icon: (
        <Box sx={{ ml: '3px' }}>
          <CalendarMonthOutlined />
        </Box>
      ),
      label: 'iCal Kalenderfil',
    },
  ]
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0

  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: '350px',
        '@media (max-width: 780px)': {
          margin: 'auto',
        },
      }}
      ref={wrapperRef}
    >
      <Button
        sx={{
          borderRadius: 0,
          mt: 2,
          width: '100%',
        }}
        startIcon={<CalendarMonthSharp />}
        variant="outlined"
        size={'large'}
        onClick={() => {
          if (isTouchDevice) {
            handleSave()
          } else {
            setOpen(!open)
          }
        }}
      >
        {epi('addToCalendar')}
      </Button>
      {open && (
        <List
          sx={{
            position: 'absolute',
            width: '100%',
            border: '1px solid gray',
            p: 0,
          }}
        >
          {calendarOption.map(({ label, link, icon, onClick }) => (
            <ListItem
              key={label}
              sx={{
                p: 0,
                zIndex: 5,
                backgroundColor: 'white',
                borderBottom: '1px solid gray',
              }}
            >
              <ListItemButton
                sx={{ p: 2 }}
                component="a"
                href={link ?? link}
                onClick={onClick ?? onClick}
                target="_blank"
              >
                {icon}
                <ListItemText sx={{ ml: 1 }} primary={label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  )
}

export default AddToCalendar
